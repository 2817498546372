// X-Small devices (portrait phones, less than 576px)
@mixin under-sm {
  @media (max-width: 575.98px) {
    @content;
  }
}

// Small devices (landscape phones, less than 768px)
@mixin under-md {
  @media (max-width: 767.98px) {
    @content;
  }
}

// Medium devices (tablets, less than 992px)
@mixin under-lg {
  @media (max-width: 991.98px) {
    @content;
  }
}

// Large devices (desktops, less than 1200px)
@mixin under-xl {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin under-xxl {
  // X-Large devices (large desktops, less than 1400px)
  @media (max-width: 1399.98px) {
    @content;
  }
}

// Small devices (landscape phones, 576px and up)
@mixin above-xs {
  @media (min-width: 576px) {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin above-sm {
  @media (min-width: 768px) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin above-md {
  @media (min-width: 992px) {
    @content;
  }
}

// X-Large devices (large desktops, 1200px and up)
@mixin above-lg {
  @media (min-width: 1200px) {
    @content;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@mixin above-xl {
  @media (min-width: 1400px) {
    @content;
  }
}
