.container {
  &.container--retain-padding {
    @include under-md {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.bg-none {
  background-color: transparent !important;
}

.bg-light {
  background-color: rgba(#CBCBCB, .17);
}