.awards-carousel {
    position: relative;

    .awards-carousel__slide {
        text-align: center;
        padding: 0 10px;

        h4 {
            font-size: 20px;
            margin: 0;
            margin-top: 1rem;
            font-family: "ITC New Baskerville", serif;
        }

        span {
            font-size: 16px;
            font-weight: 300;
            display: inline-block;
            margin-top: 6px;
        }

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }
    }
}