.recently-viewed-carousel {
  .featured-image-container {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .slick-slide {
    padding: 0 10px;
  }

  .slick-list {
    margin: 0 -10px;
  }
}
