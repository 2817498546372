.ms-content {
    p {
        color: rgba(#505050, .54);
        font-size: 18px;
        line-height: 1.8;
        margin-bottom: 2rem;

        @media (max-width: 991px) {
            font-size: 16px;
        }
    }

    h2 {
        font-family: "ITC New Baskerville",serif;
        font-size: 42px;
        color: #49484D;
        line-height: 1.2;

        @media (max-width: 767px) {
           font-size: 32px;
        }
    }

    &.ms-content--flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 767px) {
            display: block;
        }

        > * {
            &:last-child {
                margin-top: 20px;
                @media (min-width: 768px) {
                    margin-left: 40px;
                    margin-top: 0;
                }
            }
        }
    }
}