.cc-nav {
  text-align: center;
  .cc-nav__list {
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    display: flex;

    @include under-lg {
      // display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $teal;
      z-index: 999;
      flex-direction: column;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease;

      .cc-logo {
        display: none;
      }

      &.is-open {
        display: flex;
        visibility: visible;
        opacity: 1;
      }
    }

    li {
      + li {
        margin-left: 50px;

        @include under-lg {
          margin-left: 0;
          margin-top: 30px;
        }
      }

      @include under-lg {
        transform: translateY(-20px);
        transition: transform 0.2s ease;
      }

      a {
        color: white;
        font-weight: 300;
        font-size: 18px;
      }
    }
  }
}

.cc-nav {
  .cc-nav__list {
    &.is-open {
      li {
        transform: translateY(0);
      }
    }
  }
}
