.ms-info {

    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column;
        margin-top: 30px;
    }

    .ms-info__logo {
        @media (max-width: 991px) {
            img {
                max-height: 100px;
            }
        }

        @media (max-width: 767px) {
            margin-top: 30px;

            img {
                max-width: 120px;
            }
        }
    }

    h1 {
        font-family: 'AvantGarde Normal', serif;
        text-transform: uppercase;
        font-size: 48px;
        color: #909193;
        margin-bottom: 0;
        line-height: 1;

        @media (max-width: 991px) {
            font-size: 38px;
        }
    }

    .star-rating {
        display: flex;

        .star-rating__star {
            height: 24px;
            width: 25px;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512.001 512.001' style='enable-background:new 0 0 512.001 512.001;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath fill='#909193' d='M511.266,197.256c-1.764-5.431-6.458-9.388-12.108-10.209l-158.722-23.065L269.452,20.155 c-2.527-5.12-7.741-8.361-13.451-8.361c-5.709,0-10.924,3.242-13.451,8.361l-70.988,143.828L12.843,187.047 c-5.65,0.821-10.344,4.779-12.108,10.209c-1.765,5.43-0.293,11.391,3.795,15.376l114.848,111.955L92.27,482.67 c-0.965,5.627,1.349,11.315,5.968,14.67c4.618,3.355,10.74,3.798,15.797,1.142L256,423.846l141.961,74.637 c2.195,1.154,4.591,1.723,6.979,1.723c3.11,0,6.206-0.966,8.818-2.865c4.619-3.356,6.933-9.043,5.968-14.671L392.61,324.587 l114.86-111.954C511.559,208.647,513.031,202.686,511.266,197.256z M366.023,308.608c-3.536,3.446-5.15,8.412-4.314,13.278 l23.311,135.898l-122.038-64.162c-4.37-2.297-9.591-2.297-13.961,0l-122.045,64.163l23.304-135.9 c0.834-4.866-0.779-9.83-4.313-13.276l-98.731-96.244l136.445-19.829c4.886-0.71,9.108-3.778,11.294-8.205L256,60.685 l61.023,123.645c2.186,4.427,6.408,7.496,11.294,8.206l136.447,19.828L366.023,308.608z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
            background-size: 25px 24px;
            display: block;

            + .star-rating__star {
                margin-left: 5px;
            }
        }
    }

    .location {
        color: rgba(#49484D, .53);
        display: flex;
        background-image: url('../../images/location-pin.svg');
        background-repeat: no-repeat;
        background-size: 18px 24px;
        margin-top: 1.7rem;
        height: 24px;
        align-items: center;
        padding-left: 30px;
        font-size: 16px;
        background-position: left center;
    }
}