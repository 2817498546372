#footer {
  background-color: black;
  padding-top: 40px;

  .footer-nav {
    margin-bottom: 40px;
  }

  ul.business-partners {
    display: flex;
    align-items: flex-end;
    margin-top: 50px;
  }

  .p-lg {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 30px;
  }

  * {
    color: white;
  }

  hr {
    opacity: 0.1;
  }

  .copyright {
    font-size: 12px;
    font-weight: 300;
  }

  .footer-menu {
    font-size: 18px;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: block;
      a {
        font-size: 18px;
        font-weight: bold;
      }

      + li {
        margin-top: 8px;
      }
    }
  }
}

#footer .footer-navigation .nav > li > a {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.4;
}
