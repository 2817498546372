.home-hero {
  position: relative;

  .booking-options {
    @include above-md {
      position: absolute;
      bottom: 0;
      top: unset;
      background-color: rgba(20, 15, 15, 0.6);
      padding-bottom: 20px;
    }
  }

  .slick-track {
    line-height: 0;
  }

  .slick-dots {
    position: absolute;
    z-index: 10;
    bottom: 170px;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    margin: 0;

    @include under-lg {
      bottom: 20px;
    }

    li {
      display: inline-block;

      button {
        border-radius: 0;
        height: 18px;
        width: 18px;
        text-indent: -99999px;
        display: block;
        box-shadow: none;
        border: none;

        &:focus {
          outline: none;
        }

        &:hover,
        &:focus {
          background-color: #aeaeae;
        }
      }

      &.slick-active {
        button {
          background-color: $red;
          border-color: $red;
        }
      }

      + li {
        margin-left: 15px;
      }
    }
  }

  .home-hero__dots {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;

     &:before, &:after {
        display: none;
      } 

    .book-now-button {
        max-width: 100%;
        width: 348px;
        height: 64px;
        display: flex;
        align-items: center;
        align-self: flex-end;
        justify-content: center;
        text-decoration: none;
        background-color: #d71923;
        text-align: center;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        padding: 0;

        @media (max-width: 767px) {
          width: 200px;
          font-size: 15px;
          height: 50px;
        }

        span {
          color: white;
          display: block;
        }
    }
  }
}

.home-hero__slide {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  position: relative;
  z-index: 3;
  img {
    width: 100%;
    height: auto;
    max-height: 650px;
    object-fit: cover;
    min-height: 300px;

    @include above-md {
      min-height: 530px;
    }
  }
}

.home-hero__overlay {
  padding-top: 6vw;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;

  h2,
  p,
  a {
    color: white;
  }

  a {
    display: inline-block;
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;

    @include under-lg {
      font-size: 15px;
    }
  }
}

.home-hero {
  &.home-hero--cc {
    .home-hero__slide {
      img {
        max-height: 777px;
        min-height: 400px;

        @include above-md {
          min-height: 630px;
        }
      }

      .home-hero__overlay {
        padding-top: 150px;

        @include above-xl {
          padding-top: 180px;
        }

        @include under-xl {
          padding-top: 130px;
        }
      }
    }

    .booking-engine {
      .search-outer {
        input#area {
          font-weight: 300;
        }
      }
    }

    .booking-options {
      background-color: $teal;

      @include above-md {
        transform: translate3d(-50%, 30px, 0);
        left: 50%;
        max-width: 900px;
      }

      .booking-button {
        background-color: $blue;
        border-color: $blue;

        &:hover {
          background-color: white;
          border-color: white;
          span {
            color: $blue;
          }
        }
      }
    }

    .slick-dots {
      li {
        &.slick-active {
          button {
            background-color: $teal;
          }
        }
      }
    }
  }
}


.home-hero {
  &.home-hero--minisite {

    .home-hero__slide {

      img {
        max-height: 697px;
      }
      &:before {
        display: none;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: unset;
      right: unset;
      text-align: left;
      margin-bottom: 2rem;
      width: auto;

      @media (max-width: 991px) {
        position: absolute;
        bottom: -30px;
        left: 15px;
        margin-bottom: 0;
      }

      li {
        margin-bottom: 0;
        font-size: 0;

        button {
          width: 29px;
          height: 11px;
          margin-bottom: 0;
        }

        + li {
          margin-left: 10px;
        }
      }
    }
  }
}

.cc-brand {
.home-hero .slick-dots li.slick-active button {
  background-color: $teal;
}
}