@font-face {
  font-family: "ITC New Baskerville";
  src: url("../fonts/NewBaskerville-Italic.eot");
  src: url("../fonts/NewBaskerville-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/NewBaskerville-Italic.woff2") format("woff2"),
    url("../fonts/NewBaskerville-Italic.woff") format("woff"),
    url("../fonts/NewBaskerville-Italic.ttf") format("truetype"),
    url("../fonts/NewBaskerville-Italic.svg#ITC New Baskerville") format("svg");
}

@font-face {
  font-family: 'AvantGarde Normal';
  src: url('../fonts/AvantGardeITCbyBT-Book.woff2') format('woff2'),
      url('../fonts/AvantGardeITCbyBT-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


